import React from 'react'
import {LazyLoadImage} from "react-lazy-load-image-component"

const Features = () => {
    return (
        <div className='h-auto w-full page-section flex flex-col items-center lg:text-4xl text-xl  pt-10 relative background' id="features" >
            <span className='text-center  font-black ml-4 text-[#ea5765] lg:mt-14 '>FEATURES OF OUR 3D CITY MODELS</span>
          
                <div className='flex gap-5 flex-wrap justify-center md:mt-14 mt-5 pb-10 px-3'>
                    {/* <img alt="Image of" className='h-36 absolute bottom-36 left-0' src='./Assets/Features/feature2.png' /> */}
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right"  src='./Assets/Features/Asset 14.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="200" src='./Assets/Features/Asset 15.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="300" src='./Assets/Features/Asset 16.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="400" src='./Assets/Features/Asset 17.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="500" src='./Assets/Features/Asset 18.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="720" src='./Assets/Features/Asset 19.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="700" src='./Assets/Features/Asset 20.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="800" src='./Assets/Features/Asset 21.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="900" src='./Assets/Features/Asset 22.png' />
                    <img alt="Image of" className='h-36 pointer-events-none md:h-72' data-aos="fade-right" data-aos-delay="1000" src='./Assets/Features/Asset 23.png' />
                </div>
           
        </div>

    )
}

export default Features