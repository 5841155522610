import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { CursorClickIcon } from '@heroicons/react/outline';
import { AdjustmentsIcon, EyeIcon } from '@heroicons/react/solid';
import { LazyLoadImage } from "react-lazy-load-image-component"

const Gallery = () => {
    return (
        <div className='h-auto flex page-section flex-col items-center py-16 w-full bg-[#152535]' id="gallery">
            <div className='text-left flex self-center justify-center w-full font-bold lg:text-4xl text-2xl  uppercase text-white' >
                <span className='self-center'>GAllery</span>
            </div>
            <div className=' text-center w-full flex justify-center py-10' >
                {/* <span className='self-center text-left flex  w-full font-bold text-4xl  uppercase text-[#ea5765] bg-opacity-80'>GAllery</span> */}
                <Carousel labels={false} selectedItem={0} showIndicators={false} className='  w-5/6 lg:px-20  px-3'>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="../Assets/images/Gallery-psd.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/denver.jpg" />
                    </div>
                    <div >
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/alexandria.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/charlotte.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/dallas.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/hong-kong.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/phoenix.jpg" />
                    </div>
                    <div>
                        <img alt="Image of" className='h-full w-full object-contain rounded-md' src="./Assets/images/san-francisco.jpg" />
                    </div>
                </Carousel>
            </div>
            <div className='flex-col flex  items-center space-x-2 lg:w-4/6 w-full text-left  lg:py-14'>
                <div className='flex space-x-3 self-start '>
                    <img alt="Image of" className='lg:h-32 h-16 pointer-events-none' src="./Assets/Gallery/Asset 41.png" />
                    <div className='text-left   flex flex-col justify-center'>
                        <span className='lg:text-4xl md:text-3xl font-bold text-xl uppercase text-white' data-aos="fade-right" data-aos-delay="200">Toogle around</span>
                        <span className='opacity-90 text-xs md:text-lg font-normal tracking-wide text-[#ea5765] w-44 md:w-auto'>Immerse yourself into this 360° tour of Austin City
                        </span>
                    </div>
                </div>

                <div className='flex flex-col lg:flex-row  justify-center mt-5 ' >
                    <div className=' flex flex-col items-start space-y-10 px-5 '>
                        <div className='flex text-sm md:text-base'>
                            <CursorClickIcon className='md:h-10 h-8 md:mr-5 opacity-80 text-[#ea5765]' />
                            <div className='flex flex-col '>
                                <span className='text-gray-200 font-semibold'>INTERACT</span>
                                <span className='md:w-72 text-gray-400 font-normal w-44'>Click on seperate structures for their detailed information like dimensions, density, name and much more.
                                </span>
                            </div>
                        </div>
                        <div className='flex sapce-x-5 text-sm md:text-base'>
                            <AdjustmentsIcon className='md:h-10 h-8 md:mr-5 opacity-80 text-[#ea5765]' />
                            <div className='flex flex-col'>
                                <span className='text-gray-200 font-semibold'>CUSTOMIZE</span>
                                <span className='md:w-72 text-gray-400 font-normal w-44'>Change various settings like daylight condition, basemaps or even add your own set of information.
                                </span>
                            </div>
                        </div>
                        <div className='flex sapce-x-5 text-sm md:text-base'>
                            <EyeIcon className='md:h-10 h-8 md:mr-5 opacity-80 text-[#ea5765]' />
                            <div className='flex flex-col'>
                                <span className='text-gray-200 font-semibold'>
                                    VISUALIZE</span>
                                <span className='md:w-72 w-44 text-gray-400 font-normal pr-5'>Zoom, rotate, pan around to visualize the city with immense realism.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <iframe className='md:w-[600px] text-center h-96 lg:ml-4 md:p-4  lg:mt-0 mt-4' src="https://globolive3d.gl3d.in/portal/apps/experiencebuilder/experience/?id=c45529d86751453191ee4f11b4261aae"></iframe>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Gallery