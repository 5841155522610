import React, { useEffect } from 'react'
import $ from "jquery"
import { LazyLoadImage } from "react-lazy-load-image-component"
import emailjs from 'emailjs-com'

const Library = ({ modal, error }) => {
    useEffect(() => {
        $('#over-img1').hover(
            function () {
                $('#over-cities1').fadeIn('slow');
                $("#carouselExampleIndicators").hide();
            }, function () {
                $('#over-cities1').fadeOut('fast');
                $("#carouselExampleIndicators").show();
            }
        );
        $('#over-img2').hover(
            function () {
                $('#over-cities2').fadeIn('slow');
                $("#carouselExampleIndicators").hide();
            }, function () {
                $('#over-cities2').fadeOut('slow');
                $("#carouselExampleIndicators").show();
            }
        );
        $('#over-img3').hover(
            function () {
                $('#over-cities3').fadeIn('slow');
                $("#carouselExampleIndicators").hide();
            }, function () {
                $('#over-cities3').fadeOut('slow');
                $("#carouselExampleIndicators").show();
            }
        );
        $('#over-img4').hover(
            function () {
                $('#over-cities4').fadeIn('slow');
                $("#carouselExampleIndicators").hide();
            }, function () {
                $('#over-cities4').fadeOut('slow');
                $("#carouselExampleIndicators").show();
            }
        );
    }, [])
    function saveData(e) {
        e.preventDefault();

        // console.log($("#orgname").val()+","+$("#name").val()+","+$("#contactno").val()+","+$("#email").val()+","+$("#message").val()+","+$("#countryCode").val());
        var email = $("#email").val();
        function emailIsValid(email) {
            return /\S+@\S+\.\S+/.test(email)
        }

        if ($("#email").val() == "") {
            $("#alertEmail1").show();
            $("#alertEmail").hide();
        } else if (!emailIsValid(email)) {
            $("#alertEmail").show();
            $("#alertEmail1").hide();

        }
        else {
            $("#alertEmail").hide();
            $("#alertEmail1").hide();

            // emailjs.sendForm("service_7yps5sl", "template_4ml23bz", e.target, "TlvBmPilS-6iKwhak").then((res) => {

            //     modal(true)
            //     setTimeout(function () {
            //         modal(false)
            //     }, 3000);
            //     $("#orgname").val('');
            //     $("#name").val('');
            //     $("#contactno").val('');
            //     $("#email").val('');
            //     $("#message").val('');
            //     $("#countryCode").val('');

            // }).catch(err => error(true))


            $.ajax({
                type: "POST",
                url: "formData.php",
                data: {
                    organisatnName: $("#orgname").val(),
                    name: $("#name").val(), contactno: $("#contactno").val(), email: $("#email").val(), message: $("#message").val(), countrycode: $("#countryCode").val()
                },
                success: function (response) {
                    console.log(response);
                    if (response == "Pass") {

                        modal(true)
                        setTimeout(function () {
                            modal(false)
                        }, 3000);
                        $("#orgname").val('');
                        $("#name").val('');
                        $("#contactno").val('');
                        $("#email").val('');
                        $("#message").val('');
                        $("#countryCode").val('');
                    }
                    else {
                         error(true)
                         setTimeout(function () {
                            error(false)
                        }, 3000);
                        
                    }
                }

            });


        }

    }

    return (
        <div className=' flex flex-col  items-center pt-20 page-section mainbg relative bg-[#152535]' id="library" >
            <span className='font-black fon lg:text-5xl text-xl text-center  uppercase text-white' >3D Library of Cities Around the Globe</span>
            <div className='relative hidden lg:block'>
                <img alt="Image of" id="over-img1" className='na hover:scale-110 h-24 cursor-pointer ' src="./Assets/Library/na.png" />
                <img alt="Image of" id="over-img2" className='asia  hover:scale-110 h-24 cursor-pointer' src="./Assets/Library/asia.png" />
                <img alt="Image of" id="over-img3" className='eur  hover:scale-110 h-24 cursor-pointer' src="./Assets/Library/eur.png" />
                <img alt="Image of" id="over-img4" className='me  hover:scale-110 h-24 cursor-pointer' src="./Assets/Library/middleeast.png" />
                <img alt="Image of" className='' src="./Assets/Library/Asset50.png" />
                <img alt="Image of" src="./Assets/Library/asia_.png" id="over-cities2" className="over-cities2 hidden" />
                <img alt="Image of" src="./Assets/Library/me.png" id="over-cities4" className="over-cities4 hidden" />
                <img alt="Image of" src="./Assets/Library/europe.png" id="over-cities3" className="over-cities3 hidden" />
                <img alt="Image of" src="./Assets/Library/c1.png" id="over-cities1" className="over-cities1 hidden" />
                <img alt="Image of" src="./Assets/Library/c1.png" id="carouselExampleIndicators" className="over-cities1" />
            </div>
            <LazyLoadImage className='lg:hidden' src="./Assets/Library/Asset 105.png" />
            <div className='flex items-center flex-col lg:flex-row  justify-center gap-6 w-full my-10 lg:my-0'>
                <div className='flex flex-col lg:lg:w-96 w-auto pl-3'>
                    <span className='font-black lg:text-5xl text-sm lg:mt-14 uppercase text-white w-auto'>BUILD YOUR 3D CITY WITH US IN LOD 2.5</span>
                    <span className='text-gray-300 md:w-96 w-'>3D city model improve geospatial intelligence and enhance situational awareness across domains for continuous monitoring and surveillance programs, crisis intervention activities and broad area mapping.</span>
                </div>
                <LazyLoadImage className='lg:w-3/12 md:w-96 w-64' src="./Assets/Library/Asset 51.png" />
            </div>
            <div className='flex md:space-x-20 flex-col lg:flex-row items-center mt-52 mb-10 page-section' id="contacts">
                <span className='font-extrabold lg:text-5xl md:text-2xl text-lg   lg:mt-14 uppercase text-[#ea5765]'>COLLABORATE <br className='hidden lg:block' /> WITH US</span>
                <div className="col-lg-6 mt-5 mt-lg-0 w-full" >
                    <form action="" method="post" role="form" className="php-email-form flex flex-col space-y-5" onSubmit={saveData}>
                        <div className="">
                            <input required type="text" className="bg-[#152535] md:w-96 w-60 text-gray-500 rounded-md outline-none p-2 border-b-2 border-sky-700" name="subject" id="orgname" placeholder="Organisation Name" />
                        </div>
                        <div className="">
                            <input required type="text" name="name" className="bg-[#152535] md:w-96 w-60 text-gray-500 rounded-md outline-none p-2 border-b-2 border-sky-700" id="name" placeholder="Name" />
                        </div>

                        <div className=" md:w-96 w-60">
                            <input required type="number" name="countryCode" className="bg-[#152535] md:w-96 w-60 text-gray-500  rounded-md outline-none p-2 border-b-2 border-sky-700" id="countryCode" placeholder="Country code" />
                        </div>
                        <div className="">
                            <input required type="number" className="bg-[#152535] md:w-96 w-60 text-gray-500  rounded-md outline-none p-2 border-b-2 border-sky-700" name="phone" id="contactno" placeholder="Contact Number" />
                        </div>

                        <div className="">
                            <input required type="email" className="bg-[#152535] md:w-96 w-60 text-gray-500 rounded-md outline-none p-2 border-b-2 border-sky-700" name="email" id="email" placeholder="Email" />

                        </div>
                        <div className="flex  space-y-2 flex-col text-gray-500"><span id="msgcolor">Message</span>
                            <textarea className="bg-[#152535] md:w-96 w-60 text-gray-500 rounded-md outline-none p-2 border-2 border-sky-700" name="message" id="message" rows="5" placeholder=""></textarea>
                            <div className="validate"></div>
                        </div>
                        <div className="text-center"><button className='px-5 rounded-lg  py-2 text-white bg-[#ea5765]' type="submit" >Submit</button></div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Library